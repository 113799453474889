<template>
	<div class="" style="background-color: #f4f3f4">
		<div class="Main width_1200 margin_auto flex">
			<div class="Main_l">
				<img
					src="https://res.metaera.hk/resources/assets/images/1081.png"
					alt=""
					class="jszkimg"
				/>
				<div class="Main_l_bot">
					<div>MetaEra 周刊｜6.5-6.11</div>
					<div>
						MetaEra周刊第114期，带您回顾一周热点、重要新闻、行情与合约数据、矿业信息、项目动态、技术进展等全行业资讯动态
					</div>
				</div>
			</div>
			<div class="MainList">
				<div class="MainItem tophit" ref="tophit">
					<div class="MainItemHead flex align_items_c">
						<div>本周热点</div>
					</div>
					<div
						class="wzitem wzitem2 flex align_items_c"
						v-for="(item, index) in topLists"
						:key="index"
					>
						<div class="flex align_items_c justify_content_c">快讯</div>
						<div>{{ item.title }}</div>
					</div>
					<div class="wzitem wzitem2 flex align_items_c">
						<a href="">展开更多</a>
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<div class="MainItem componey" ref="componey">
					<div class="MainItemHead flex align_items_c">
						<div>风云人物/机构</div>
					</div>
					<div
						class="wzitem wzitem2 flex align_items_c"
						v-for="(item, index) in selectedspecial"
						:key="index"
					>
						<div class="flex align_items_c justify_content_c">快讯</div>
						<div>{{ item.name }}</div>
					</div>
					<div class="wzitem wzitem2 flex align_items_c">
						<a href="">展开更多</a>
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<div class="MainItem resou" ref="resou">
					<div class="MainItemHead flex align_items_c">
						<div>MetaEra 热文</div>
					</div>
					<div
						class="wzitem flex align_items_c"
						v-for="(item, index) in topLists"
						:key="index"
					>
						<img :src="item.pic" :key="item.pic" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one">{{ item.title }}</div>
							<div v-if="item.author">{{ item.author.nickname }}</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/3.png"
							alt=""
						/>
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/3.png"
							alt=""
						/>
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/3.png"
							alt=""
						/>
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/3.png"
							alt=""
						/>
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
				</div>
				<div class="MainItem rongzi" ref="rongzi">
					<div class="MainItemHead flex align_items_c">
						<div>热门融资信息</div>
					</div>
					<div
						class="wzitem wzitem2 flex align_items_c"
						v-for="(item, index) in InvestDetails"
						:key="index"
					>
						<div class="flex align_items_c justify_content_c">快讯</div>
						<div>{{ item.title }}</div>
					</div>
					<div class="wzitem wzitem2 flex align_items_c">
						<a href="">展开更多</a>
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<div class="MainItem event" ref="event">
					<div class="MainItemHead flex align_items_c">
						<div>大事抢先知</div>
					</div>
					<div
						class="wzitem wzitem2 flex align_items_c"
						v-for="(item, index) in EventDetails"
						:key="index"
					>
						<div class="flex align_items_c justify_content_c">快讯</div>
						<div>{{ item.title }}</div>
					</div>
					<div class="wzitem wzitem2 flex align_items_c">
						<a href="">展开更多</a>
						<i class="el-icon-arrow-down"></i>
					</div>
				</div>
			</div>
			<div class="steps">
				<div class="js-box">
					<div class="js-box-right-box">
						<div class="nav-box">
							<ul class="nav-list">
								<li
									v-for="(item, index) in steps"
									:key="index"
									:class="{ active: active == index }"
									class="pointer"
									@click="md(item, index)"
									>{{ item.name }}</li
								>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { homeLinsts, getSpecial, getEventDetails } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			steps: [
				{
					name: "发现新项目",
					ref: "tophit",
				},
				{
					name: "DAO",
					ref: "rongzi",
				},
				{
					name: "查询 Layer 2",
					ref: "componey",
				},
				{
					name: "NFT",
					ref: "resou",
				},
				{
					name: "DeFi",
					ref: "event",
				},
				{
					name: "跨链",
					ref: "componey",
				},
				{
					name: "其他",
					ref: "resou",
				},
				{
					name: "元宇宙",
					ref: "event",
				},
			],
			active: 0,
			topLists: [],
			selectedspecial: [],
			InvestDetails: [],
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		stepChange(val) {
			console.log(val);
		},
		md(item, index) {
			this.active = index;
			this.$el
				.querySelector("." + item.ref)
				.scrollIntoView({ block: "start", behavior: "smooth" });
		},
		getDataList() {
			homeLinsts({})
				.then((res) => {
					if (res.code == 200) {
						// this.bnnerLists = res.data.bnnerLists
						// this.zbtjList=res.data.bnnerLists.slice(0,4)
						// this.categoryLists = res.data.categoryLists
						// this.recommendLists = res.data.recommendLists
						this.topLists = res.data.topLists;
						this.flashLists = res.data.flashLists;
						// this.activeName = 1
						// this.getArticleList({ category_id: res.data.categoryLists[0].id, type: 1, page: 1, size: 25 }, 0)
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			getSpecial({})
				.then((res) => {
					if (res.code == 200) {
						this.selectedspecial = res.data.selected;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			getEventDetails({})
				.then((res) => {
					if (res.code == 200) {
						this.EventDetails = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
/* Main */
.Main {
	padding-top: 36px;
}

.MainList {
	margin-left: 25px;
	padding-bottom: 20px;
}

.MainItem {
	width: 686px;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	margin-top: 20px;
}

.MainItem:nth-child(1) {
	margin-top: 0;
}

.MainItemHead {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #efefef;
	border-radius: 20px 20px 0 0;
	position: relative;
}

.MainItemHead div {
	position: relative;
	margin-left: 20px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-left: 20px;
}

.MainItemHead div::after {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 3px;
	height: 18px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
}

.wzitem {
	width: 94%;
	margin: 0 auto;
	height: 142px;
	border-bottom: 1px solid #efefef;
}

.wzitem img {
	width: 160px;
	height: 100px;
	border-radius: 5px;
}

.wzitemL {
	width: 70%;
	margin-left: 21px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemL div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzitemL div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a7;
}

.wzitem2 {
	height: 62px;
}

.wzitem2 div:nth-child(1) {
	width: 42px;
	height: 21px;
	background: #ffffff;
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	border: 1px solid #0056ff;
	font-size: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.wzitem2 div:nth-child(2) {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-left: 10px;
}

.wzitem2 a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.wzitem2 i {
	color: #0056ff;
}

.steps {
	width: 100px;
	margin-left: 10px;
}

.stepsTime {
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	font-weight: 400;
	color: #000000;
}

.stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000 !important;
	margin-top: 5px;
}

.js-box {
	margin-top: 0;
	position: fixed;
}
</style>
<style></style>
